<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-back-button text="" slot="start" :defaultHref="{name: 'home'}" />

				<ion-title>
					<ion-label v-if="course">{{ course.name }}</ion-label>
				</ion-title>
				
				<div slot="end" class="user-wrapper" @click="$router.push(isLoggedIn ? `/profile/${user.id}` : '/login')">
					<p class="web-version">{{ isLoggedIn ? 'Profil' : 'Prihlásiť sa' }}</p>
					<ion-icon :icon="isLoggedIn ? personCircleOutline : logInOutline"></ion-icon>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<div class="video-background">
				<div v-if="lesson && lessonGuide" class="video-wrapper d-flex ion-justify-content-center ion-align-items-center">
					<iframe v-if="lessonGuide.type == 'youtube'||lessonGuide.type == 'gdrive'" :src="lessonGuide.url" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
					<a target="_blank" :href="lessonGuide.url" v-else style="color: white;">{{lessonGuide.url}}</a>
				</div>
			</div>
			<w-loadable :items="lesson" class="container course-content">

				<div class="course-content-title-wrapper d-flex ion-justify-content-between">
					<h2>{{ lesson.name }}</h2>
					<div class=" d-flex ion-align-self-end">
						<!-- <div class="mobile-hidden">
							<ion-icon :icon="starOutline"></ion-icon>
						</div>
						<div>
							<ion-icon :icon="checkmarkCircleOutline"></ion-icon>
						</div> -->
						<div>
							<ion-icon :icon="shareSocial" @click="shareLessonLink"></ion-icon>
						</div>
					</div>
				</div>

				<div v-if="lessonWhy">
					<h2>Prečo sa to učiť?</h2>
					<p class="ml-1">{{lessonWhy.block_content}}</p>
				</div>
				<div v-if="lessonResources">
					<h2>Užitočné veci</h2>
					<p class="ml-1" v-for="link in resourceLinks" :key="link"><a :href="link">{{link}}</a></p>
				</div>
				<div v-if="lessonChallenges">
					<h2>Výzvy</h2>
					<p class="ml-1" v-for="text in challengeLinks" :key="text">{{text}}</p>
				</div>
			</w-loadable>
		</ion-content>
	</ion-page>
</template>

<script>
import { toastController } from '@ionic/vue'
import { shareSocial, personCircleOutline, logInOutline } from 'ionicons/icons'
import wAxios from '@/plugins/w/axios'
import WLoadable from '../../w/loadable/w-loadable.vue'
import { mapGetters} 	from 'vuex'

const api = {
	getCourse: (slug) => wAxios.get_auth_data(`https://open-academy.sk/cms/api/courses/slug/?q=${slug}`),
	getlesson: (slug) => wAxios.get_auth_data(`https://open-academy.sk/cms/api/lessons/slug/?q=${slug}`),
}

export default {
	component: {
		WLoadable
	},

	data() {
		return {
			lesson: {},
			shareSocial, 
			personCircleOutline, 
			logInOutline,
			nextLesson: null,
			previousLesson: null,
			course: null
		}
	},

	async created() {
		try {
			this.lesson = await api.getlesson(this.$route.params.lessonSlug)
			this.course = await api.getCourse(this.$route.params.courseSlug)
			
			// console.log(this.lesson)
			// console.log(this.course)

			this.getPrevAndNext()
		} catch (error) {
			console.log(error)
		}

		// console.log("course: " + this.$route.params.courseSlug)
		// console.log("lesson: " + this.$route.params.lessonSlug)

	},

	methods: {
		// presentToast,
		getPrevAndNext() {
			let lessons = []
			this.course.lessons.forEach(lessonGroup => {
				lessons = [...lessons, ...lessonGroup.group_lessons]
			})

			let i = null
			lessons.forEach((lesson, index) => {
				if(lesson.id == this.lesson.id)
					i = index
			})
			this.previousLesson = lessons[i-1]
			this.nextLesson = lessons[i+1]
		},
		// toggleFavorite() {
		// 	console.log(this.user)
		// 	// try {
		// 	// 	await wAxios.post_auth(`api/lessons//stars`)
		// 	// } catch(e) {

		// 	// }
		// },
		async shareLessonLink(event) {
			const currentUrl = window.location.href
			const inputCopy = document.body.appendChild(document.createElement('input'))
			inputCopy.value = currentUrl
			inputCopy.focus()
			inputCopy.select()
			document.execCommand("copy")	
			inputCopy.parentNode.removeChild(inputCopy)
			const toast = await toastController
				.create({
					message: 'Link bol skopírovaný.',
					color: "secondary",
					duration: 2000
				})
			return toast.present()
		},

		// async openToast() {
		// 	const toast = await toastController
		// 		.create({
		// 			message: 'Link bol skopírovaný.',
		// 			duration: 2000
		// 		})
		// 	return toast.present();
		// },
	},
	
	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),

		lessonGuide() {
			if(!this.lesson.content)
				return null
				
			const guide = this.lesson.content.find(block => block.block_name == "@guide")
			
			if(!guide)
				return null
			
			const regExpYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
			const regExpGDrive = /^.*(drive.google.com\/file\/d\/)([^#&?]*)\//
			const regExpGDrivePresentation = /^.*(docs.google.com\/(presentation|document)\/d\/)([^#&?]*)\//
			
			if(guide.block_content.match(regExpYoutube)) {
				const match = guide.block_content.match(regExpYoutube)
				return {
					type: 'youtube',
					url: `https://www.youtube.com/embed/${match[2]}?enablejsapi=1`
				}
			}
			if(guide.block_content.match(regExpGDrive)||guide.block_content.match(regExpGDrivePresentation)){
				let match = guide.block_content.match(regExpGDrive)
				if (!match) {
					match = guide.block_content.match(regExpGDrivePresentation)
				}
				return {
					type: 'gdrive',
					url: `https://drive.google.com/file/d/${match[3] || match[2]}/preview`
				}
			}
			return {type: 'url', url: guide.block_content}
		},
		lessonWhy() {
			if(this.lesson.content) {
				return this.lesson.content.filter(block => block.block_name == "@why")[0]
			}
			return []
		},
		lessonChallenges() {
			if(this.lesson.content) {
				return this.lesson.content.filter(block => block.block_name == "@challenges")[0]
			}
			return []
		},
		lessonResources() {
			if(this.lesson.content) {
				return this.lesson.content.filter(block => block.block_name == "@resources")[0]
			}
			return []
		},
		
		resourceLinks () {
			if(this.lessonResources.block_content){
				if (this.lessonResources.block_content.includes("<br>")) {
					return this.lessonResources.block_content.split("<br>")
				}
				return [this.lessonResources.block_content]
			}
			return []
		},

		challengeLinks () {
			if(this.lessonChallenges.block_content) {
				if(this.lessonChallenges.block_content.includes("<br>")) {
					return this.lessonChallenges.block_content.split("<br>")
				}
				return [this.lessonChallenges.block_content]
			}
			return []
		}
	},

	watch: {
		'$route.params': {
			deep: true, 
			handler: function() {
				console.log("lesson: " + this.$route.params.lessonSlug)
			}
		}
	}
}
</script>

<style lang="sass" scoped>

.video-background 
	width: 100vw
	height: calc(100vw / 16 * 9)
	max-height: 550px
	background-color: black

	.video-wrapper
		width: 100%
		height: 100%

	iframe 
		width: 100%
		height: 100%
		max-width: 978px

	a 
		color: white
		font-size: 20px
		text-align: center
		max-width: 90%

.course-content
	padding: 15px

	a 
		color: #289FFD

	h2
		font-size: 22px

	.course-content-title-wrapper
		border-bottom: 1px solid rgba(0, 0, 0, .2)
		width: 100%
		h2
			font-size: 30px
			margin: 15px 0 0 0

		ion-icon
			height: 30px
			width: 30px
			margin: 0 5px
			cursor: pointer
</style>